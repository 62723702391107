@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.arrow {
  position: relative;
}

.arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #161c2e transparent;
  border-width: 20px 30px 0;
  bottom: -20px;
  left: 50%;
  margin-left: -30px;
}

.animate-fadein {
  animation: fadein 2s 1;
}


@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}